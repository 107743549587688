import React from 'react';
import logo from './img/logo.png';
import placeholder from './img/placeholder.png'

function getLogo(company) {

}

class Widget extends React.Component {
    render(){
      const {source, title, author} = this.props;
      console.log(title);
      return(
          <div style={{cursor:"pointer"}} onClick={()=>window.open(this.props.url,"_blank")}>
              <div className="hide-on-small-only" style={{display:"flex", flexDirection:"row"}}>
                  {/* <img style={{borderRadius:"20px 0px 0px 20px"}} width="50%" src={placeholder}/> */}
                  <div >
                      <h5 style={{paddingBottom:"10px"}}> {this.props.author} </h5>
                      <h4 style={{paddingBottom:"20px"}}> {this.props.title} </h4>
                      {/* <h5> {this.props.content.truncate(40)} </h5> */}
                      <i className = "source"> {this.props.source} </i>
                  </div>
              </div>
              <div className="hide-on-med-and-up">
                  {/* <img style={{borderRadius:"20px 20px 0px 0px"}} width="100%" src={placeholder}/> */}
                  <div >
                      <h5 style={{paddingBottom:"10px"}}> {this.props.author} </h5>
                      <h4 style={{paddingBottom:"20px"}}> {this.props.title} </h4>
                      <i> {this.props.source} </i>
                  </div>
              </div>
          </div>
      );
    }

}

export default Widget;
