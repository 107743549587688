import React from 'react';
import logo from './img/logo.png';
import './App.css';
import Widget from './Widget';
import SourceWidget from './SourceWidget';
import ReactLoading from 'react-loading';
import FadeIn from 'react-fade-in';
import { usePromiseTracker, trackPromise } from "react-promise-tracker";
let sourceData = require('./sourceData.json');
console.log(sourceData, 'the json obj');

var HttpClient = function() {
    this.get = function(aUrl, aCallback) {
        var anHttpRequest = new XMLHttpRequest();
        anHttpRequest.onreadystatechange = function() {
            if (anHttpRequest.readyState == 4 && anHttpRequest.status == 200){
                aCallback(anHttpRequest.responseText);
            }
        }

        anHttpRequest.open( "GET", aUrl, true );
        anHttpRequest.send( null );
    }
}

class App extends React.Component {
  constructor(){
    super();
    this.state = {
      articleShowing: false,
      article: "",
      title:"",
      author:"",
      source:"",
      loading:false,
      slant: null,
      credibility: null,
      readTime: null,
      content:"",
      sourceSlant:""
    }
  }

  componentDidMount(){
    if(window.location.href.includes("url=")){
        this.runUrl(window.location.href.split("url=")[1]);
    }
  }


  handleKeyPress = (e) =>{
    if(e.key === 'Enter'){
        this.runUrl(e.target.value);
    }
  }

  runUrl = (url) =>{
    if(!this.validURL(url)){
        window.alert('That is not a valid url.');
    }
    else{
      var client=new HttpClient();
      var self = this;
      var entry = url;
      this.setState({
        loading:true,
        articleShowing: false,
      });
      entry = entry.split("?")[0];
      client.get('https://api.nobias.me/v1/stories/author?url='+entry, function(response) {
          const url = new URL(entry);
          var host = "";

          var obj = JSON.parse(response);
          console.log(obj);
          if(obj.hasOwnProperty('error')){ //doesn't exist in back-end, this is a center-____ article.
            console.log("whitelist: " + url.hostname)
            if (url.hostname.substring(0,4) == "www.") {
              host = url.hostname.substring(4);
            }
            console.log("host: " + host);
            var sourceSlant = "";
            var sourceCredibility = "";
            

            for (var i=0 ; i < sourceData.length ; i++)
              {
                  if (sourceData[i].sourceUrl == host) {
                      console.log("found json obj");
                      sourceSlant = sourceData[i].sourceSlant;
                      sourceCredibility = sourceData[i].sourceCredibility;
                  }
              }

            if (sourceSlant == "center") {
              sourceSlant = "center-center"
            } else if (sourceSlant == "other") {
              sourceSlant = "N/A"
            }
            if (sourceCredibility == 1) {
              sourceCredibility = "Highly Credible";
            } else if (sourceCredibility == 2) {
              sourceCredibility = "Very Credible";
            } else if (sourceCredibility == 3) {
              sourceCredibility = "Somewhat Credible";
            } else if (sourceCredibility == 4) {
              sourceCredibility = "Credible Local News";
            } else {
              console.log(sourceCredibility);
              sourceCredibility = "Unknown Credibility";
            }

            self.setState({
              article: entry,
              author: "",
              source: "This article's slant isn't in our database, but here's a bit about the source.",
              title: "Source-level insights",
              slant: sourceSlant,
              credibility: sourceCredibility,
              readTime: 5,
              sourceSlant: sourceSlant,
            });

            self.setState({
              loading:false,
              articleShowing: true,
            });
            if(!window.location.href.includes(url)){
                  window.history.pushState(null, '', '?url='+url);
            }


          }
          else{
            const url = new URL(entry);
            var host = "";

            var obj = JSON.parse(response);
            console.log(obj);
            
              console.log("whitelist: " + url.hostname)
              if (url.hostname.substring(0,4) == "www.") {
                host = url.hostname.substring(4);
              }
              console.log("host: " + host);
              var sourceSlant = "";
              var sourceCredibility = "";
            
            for (var i=0 ; i < sourceData.length ; i++)
              {
                  if (sourceData[i].sourceUrl.toLowerCase() == host.toLowerCase()) {
                      console.log("found json obj");
                      sourceSlant = sourceData[i].sourceSlant;
                      sourceCredibility = sourceData[i].sourceCredibility;
                  }
              }


            if (sourceSlant == "center") {
              sourceSlant = "center-center"
            } else if (sourceSlant == "other") {
              sourceSlant = "N/A"
            }
 
            self.setState({
              article:entry,
              author: obj['author'],
              source: obj['source'],
              title: obj['title'],
              sourceSlant: sourceSlant,
              content: obj['content'],
              
            });

            console.log("source: " + self.state.source)

            var myHeaders = new Headers();
            myHeaders.append("Content-Type", "application/json");

            var raw = JSON.stringify([url])

            var requestOptions = {
              method: 'POST',
              headers: myHeaders,
              body: raw,
              redirect: 'follow'
            };

            const proxyurl = "https://cors-anywhere.herokuapp.com/";
            fetch(proxyurl + "https://api.nobias.me/v1/stories/search", requestOptions)
              .then(response => response.json())
              .then(data => data[0])
              .then(res =>
                self.setState({
                  slant: res.articlePoliticalSlant,
                  credibility: res.articleCredibility,
                  readTime: res.articleReadTimeInMinutes,
                }),
                )
              .then(() => console.log("slant: " + self.state.slant))
              .then(() => console.log("credibility: " + self.state.credibility))
              .then(() => console.log("readTime: " + self.state.readTime))
              .catch(error => console.log('error', error));

                self.setState({
                  loading:false,
                  articleShowing: true,
                });
                if(!window.location.href.includes(url)){
                      window.history.pushState(null, '', '?url='+url);
                }
                console.log(self.state);
            // });
          }
      });
    }
  }

  validURL = (str) => {
    var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
      '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
      '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
      '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
    return !!pattern.test(str);
  }

  render(){
    const LoadingIndicator = props => {
          const { promiseInProgress } = usePromiseTracker();

         return (
           promiseInProgress &&
          <ReactLoading type='spin' color='222222'/>
      );
     }
    return (
      <div className="row" style={{padding:"5%"}}>

      <a href="https://chrome.google.com/webstore/detail/nobias/gibdobnmepamhkigmgacgcdmidpeckda?hl=en-US" style = {{borderRadius:"6px", color: "white", textalign: "center", fontSize: "16px", fontWeight:"bolder", position: "absolute", marginTop:"50px", padding: "10px", paddingLeft: "15px", paddingRight: "15px", marginRight: "100px", right: "0", backgroundColor: "green"}}>Download the Extension</a>

        <img style={{cursor:"pointer"}} onClick={()=>window.location.href = window.location.href.split("?")[0]} src={logo} width="300px" />
        <div style={{fontSize:"30px", fontWeight:"bold"}}>Know the bias and credibility of any article </div>
        <div className="input-field" >
            <input style={{fontSize:"25px", color:"#afc4e0"}} onFocus={(event) => event.target.select()} autoFocus="autofocus" placeholder="Enter an article link" onKeyDown={this.handleKeyPress}/>
        </div>
        {this.state.loading &&
          <div style={{
              position: 'absolute', left: '50%', top: '50%',
              transform: 'translate(-50%, -50%)'
          }}>
              <ReactLoading type='spin' color='222222'/>
          </div>
        }
        {this.state.articleShowing &&
            <FadeIn>
                  <div className="myrow">
                      <div className = "mycol1" style={{padding:"10px"}}>
                          <Widget content = {this.state.content} author={this.state.author} title={this.state.title} source={this.state.source} url={this.state.article}/>
                      </div>
                      <div className = "mycol2" style={{flexGrow:"2", padding:"10px"}}>
                          <SourceWidget slant={this.state.slant} sourceSlant = {this.state.sourceSlant} credibility={this.state.credibility} readTime = {this.state.readTime}/>
                      </div>
                  </div>
            </FadeIn>
        }
      </div>
    );
  }

}

export default App;
