import React from 'react';
import logo from './img/logo.png';
import placeholder from './img/placeholder.png'
import basiclogo from './img/basiclogo.png'
import { Divider } from 'rsuite';

function returnReadInfo(time) {
  if (parseInt(time) <= 1) {
    return {label: "quick read", color: "#6FAE7E"}
  }
  else if (parseInt(time) <= 7) {
    return {label: "average read", color: "#E7BA53"}
  }
  else {
    return {label: "long read", color: "#99393A"}
  }
}

class SourceWidget extends React.Component {
    constructor(){
        super();
    }

    render(){
      console.log("PROPS: " + this.props.slant)
      console.log("credibility is " + this.props.credibility)

      console.log("READTIME is " + this.props.readTime)

      const ColoredLine = ({ color }) => (
        <vl
            style={{
                color: color,
                backgroundColor: color,
                height: 5
            }}
        />
    );

    

      const credibilityAddlInfo = {
        "Very Credible":{color:"#6FAE7E", description:"This is a top regional news source."}, 
        "Highly Credible":{color:"#6FAE7E", description:"This is a top international, national, or business news source."}, 
        "Somewhat Credible":{description:"This source belongs to a broad range of news sources of good editorial quality.", color:"#E7BA53"},
        "Credible Local News":{description:"This source credibly covers local news.", color:"#6FAE7E"},
        "Unknown Credibility":{description:"We don't have enough information to rate the credibility of this author or source.", color:"#d3d3d3"},
      };
      const slantAddlInfo = {
        "Center": {color:"#9183CC", label: "center", description:"This article does not favor either conservative or liberal ideas and causes."},
        "center-center": {color:"#9183CC", label: "center", description:"This center source does not favor either conservative or liberal ideas and causes."},
        "Likely Right":{color:"#99393A", label: "leans center-right", description:"This article slightly favors conservative ideas and causes."}, 
        "center-right":{color:"#99393A", label: "likely center-right", description:"This center-right source slightly favors conservative ideas and causes."}, 
        "right":{color:"#99393A", label: "leans right", description:"This right-leaning source favors conservative ideas and causes."}, 
        "left":{color:"#4267B2", label: "leans left", description:"This left-leaning source favors liberal ideas and causes."}, 
        "Reliably Right":{color:"#99393A", label: "leans right", description:"This article favors conservative ideas and causes."}, 
        "Likely Left":{color:"#4267B2", label: "leans center-left", description:"This article slightly favors liberal ideas and causes."}, 
        "center-left":{color:"#4267B2", label: "likely center-left", description:"This center-left source slightly favors liberal ideas and causes."}, 
        "Reliably Left":{color:"#4267B2", label: "leans left", description:"This article favors liberal ideas and causes."},
        "N/A":{color:"#d3d3d3", label: "leans unknown", description:"We do not have enough information to determine this source's bias."}

      };
      return(
          <div className="borderedNobias">
              <div style={{"paddingTop":"5px", "paddingBottom":"20px", "paddingLeft":"20px", "paddingRight":"20px"}}>
                  {this.props.slant && this.props.credibility &&
                      // <div>
                      //   {this.props.slant != this.props.sourceSlant &&  <div className = "grayText">Article Insights</div>}
                      //   {this.props.slant != this.props.sourceSlant && <div className = "mainText" style={{ color:slantAddlInfo[this.props.slant].color}}> {slantAddlInfo[this.props.slant].label}</div>}
                      //   {this.props.slant != this.props.sourceSlant && <div className = "descriptionText"> {slantAddlInfo[this.props.slant].description}</div>}
                        
                      //   <div className = "grayText">Source Insights</div>                      
                      //   <div className = "mainText"> 
                      //     <span style={{color:credibilityAddlInfo[this.props.credibility].color}}>{this.props.credibility}</span> 
                      //     <span style={{padding: 8, fontWeight:100, fontSize: 25}}>|</span> 
                      //     <span style={{ color:slantAddlInfo[this.props.sourceSlant].color}}>{this.props.sourceSlant} </span> 
                      // </div> 

                      <div>
                        <div className = "grayText">Political Slant</div>
                        <div className = "mainText" style={{ color:slantAddlInfo[this.props.slant].color}}> {slantAddlInfo[this.props.slant].label}</div>
                        <div className = "descriptionText"> 
                          <span>{slantAddlInfo[this.props.slant].description}</span>
                          {this.props.slant != this.props.sourceSlant && <span> {slantAddlInfo[this.props.sourceSlant].description}</span>}
                          {this.props.slant == this.props.sourceSlant && <span>  The article's leaning is not available at this time. </span>}
                        </div>
                        
                        <div className = "grayText">Credibility</div>                      
                        <div className = "mainText" style={{color:credibilityAddlInfo[this.props.credibility].color}}>{this.props.credibility}</div> 
                      
                      <div className = "descriptionText" > {credibilityAddlInfo[this.props.credibility].description} </div>
                      
                      <div className = "grayText">Readability</div>
                      <div className = "mainText" style={{color: returnReadInfo(this.props.readTime).color}}> {returnReadInfo(this.props.readTime).label} </div>
                      <div className = "descriptionText"> {this.props.readTime}-min read </div>
                    </div>
                  }
                  <br></br>
                  <br></br>
                  <hr></hr>
                  <div className = "readmore">
                  To read more about how we determine slant and credibility, <a href="https://nobias.com/criteria" target="_blank">click here</a>
                  <img className = "basicLogo" src={basiclogo}></img>
                  </div>

              </div>
          </div>
      );
    }

}

export default SourceWidget;
